import "./styles.scss"

import React from "react"

const Content = () => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <p>
          Niniejsza polityka prywatności (dalej:{" "}
          <strong>„Polityka prywatności”</strong>) dotyczy zasad przetwarzania
          danych osobowych przez serwis internetowy Trust Investment Projekt 23
          Sp. z o.o., dostępny pod adresem: https://trustinvestmentprojekt23.pl,
          dalej: <strong>„Serwis”</strong>.
        </p>
        <p>
          Polityka prywatności została sporządzona w zgodzie z przepisami
          Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o ochronie danych), dalej: <strong>„RODO”</strong>.
        </p>
        <p>
          Na potrzeby Polityki prywatności, określenie{" "}
          <strong>„Użytkownik”</strong> oznacza osobę fizyczną odwiedzającą
          Serwis lub korzystającą z funkcjonalności Serwisu we własnym imieniu
          lub jako reprezentant osoby prawnej.
        </p>
        <p>
          Przed rozpoczęciem korzystania z Serwisu każdy Użytkownik zobowiązany
          jest zapoznać się z niniejszą Polityką prywatności, zaakceptować jej
          zapisy oraz przestrzegać jej postanowień.
        </p>

        <h3>ADMINISTRATOR</h3>
        <p>
          Administratorem danych osobowych jest spółka{" "}
          <strong>
            Trust Investment Projekt 23 spółka z ograniczoną odpowiedzialnością
          </strong>{" "}
          z siedzibą w Kielcach przy ul. Robotniczej nr 1, 25-662 Kielce,
          wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego
          prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS: 0001022068, NIP:
          9592059901, REGON: 524635572, zwana dalej: <strong>„Spółką”</strong>,{" "}
          <strong>„Trust”</strong> lub <strong>„Administratorem”</strong>.
        </p>
        <p>Administrator nie wyznaczył Inspektora Danych Osobowych.</p>
        <p>
          Kontakt z Administratorem możliwy jest poprzez przesłanie wiadomości
          mailowej przez formularz znajdujący się na stronie:
          https://trustinvestmentprojekt23.pl/kontakt/ bądź w formie pisemnej na
          adres jego siedziby tj. ul. Robotnicza 1, 25-662 Kielce.
        </p>

        <h3>DANE ZBIERANE PRZEZ ADMINISTRATORA</h3>
        <ol>
          <li>
            W związku z korzystaniem z Serwisu, Administrator może zbierać
            następujące dane osobowe:
          </li>
          <ol>
            <li>
              imię i nazwisko, adres e-mail, numer telefonu – wprowadzone w celu
              założenia konta w Serwisie oraz skorzystania z opcji kontaktu na
              skutek wypełnienia formularza kontaktowego;
            </li>
            <li>
              imię i nazwisko, adres e-mail, numer telefonu, PESEL, numer NIP,
              numer REGON, numer dowodu osobistego, adres zamieszkania, adres
              korespondencyjny, numer rachunku bankowego, bank prowadzący
              rachunek, właściwy urząd skarbowy – wprowadzone przez osobę
              fizyczną w celu uzupełnienia profilu konta Użytkownika w Serwisie
              oraz zawarcia za pośrednictwem Serwisu umowy inwestycyjnej
              pożyczka pomiędzy Administratorem i Użytkownikiem, w oparciu o
              którą Użytkownik udziela pożyczki Administratorowi.{" "}
            </li>
            <li>
              nazwa firmy, forma prawna, adres e-mail, numer telefonu, numer
              KRS, numer NIP, numer REGON, adres siedziby, adres
              korespondencyjny, numer rachunku bankowego, bank prowadzący
              rachunek, właściwy urząd skarbowy – wprowadzone przez osobę prawną
              w celu uzupełnienia profilu konta Użytkownika w Serwisie oraz
              zawarcia za pośrednictwem Serwisu umowy inwestycyjnej pożyczka
              pomiędzy Administratorem i Użytkownikiem, w oparciu o którą
              Użytkownik udziela pożyczki Administratorowi.
            </li>
          </ol>
          <li>
            Podanie danych, o których mowa powyżej jest dobrowolne, jednakże:
          </li>
          <ol>
            <li>
              podanie danych wskazanych w punkcie I. 1) powyżej jest konieczne w
              celu założenia konta w Serwisie oraz w celu skorzystania z opcji
              kontaktu na skutek wypełnienia formularza kontaktowego.
            </li>
            <li>
              podanie danych wskazanych w punkcie I. 2) i 3) powyżej jest
              konieczne w celu zawarcia umowy inwestycyjnej pożyczka, na
              podstawie której Użytkownik udziela pożyczki Administratorowi.{" "}
            </li>
          </ol>
        </ol>

        <h3>CELE I PODSTAWA PRZETWARZANIA DANYCH OSOBOWYCH</h3>
        <p>Dane osobowe przetwarzane są w następujących celach:</p>
        <ol>
          <li>
            Założenie oraz utrzymanie konta w Serwisie – na podstawie art. 6
            ust. 1 pkt b) RODO w celu wykonania umowy.
          </li>
          <li>
            Obsługa złożonego przez Użytkownika zapytania poprzez wypełnienie
            formularza kontaktowego - na podstawie art. 6 ust. 1 pkt b) RODO w
            celu wykonania umowy.
          </li>
          <li>
            Przechowywanie danych w profilu Użytkownika - na podstawie art. 6
            ust. 1 pkt b) RODO w celu wykonania umowy.
          </li>
          <li>
            Obsługa zawarcia umowy inwestycyjnej pożyczki - na podstawie art. 6
            ust. 1 pkt b) RODO w celu wykonania umowy oraz na podstawie art. 6
            ust. 1 pkt f) RODO w celu realizacji prawnie uzasadnionego interesu
            Administratora.
          </li>
          <li>
            Ustalenie, dochodzenie lub obrona roszczeń - na podstawie art. 6
            ust. 1 pkt f) RODO w celu realizacji prawnie uzasadnionego interesu
            Administratora.
          </li>
          <li>
            Zapobieganie oszustwom oraz nadużyciom - na podstawie art. 6 ust. 1
            pkt f) RODO w celu realizacji prawnie uzasadnionego interesu
            Administratora.
          </li>
          <li>
            Kierowanie do Użytkownika treści o charakterze handlowym i
            marketingowym – na podstawie art. 6 ust. 1 pkt a) RODO w związku z
            dobrowolnie wyrażoną zgodą przez Użytkownika.
          </li>
        </ol>

        <h3>OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH</h3>
        <p>
          Okres przetwarzania danych przez Administratora zależy od rodzaju
          świadczonej usługi i celu przetwarzania. Co do zasady dane
          przetwarzane są przez czas świadczenia usługi lub do czasu zgłoszenia
          skutecznego sprzeciwu względem przetwarzania danych.
        </p>
        <p>
          Okres przetwarzania danych może być przedłużony w przypadku, gdy
          przetwarzanie jest niezbędne do ustalenia i dochodzenia ewentualnych
          roszczeń lub obrony przed roszczeniami (o okres przedawnienia
          roszczeń), a po tym czasie jedynie w przypadku i w zakresie, w jakim
          będą wymagać tego przepisy prawa.
        </p>
        <p>
          W przypadku, w którym okres przechowywania danych wynika z przepisów
          prawa, dane będą przechowywane przez okres wskazany w takich
          przepisach.
        </p>
        <p>
          Dane przetwarzane na podstawie zgody udzielonej przez Użytkownika,
          będą przechowywane do momentu wycofania zgody.
        </p>

        <h3>ODBIORCY DANYCH OSOBOWYCH</h3>
        <p>
          W związku z realizacją usług przez Administratora dane osobowe mogą
          być ujawniane zewnętrznym podmiotom, w tym w szczególności: dostawcom
          usług IT pozwalającym na prawidłowe korzystanie z Serwisu, podmiotom
          wspierającym działania marketingowe Administratora oraz dostawcom
          usług księgowych oraz prawniczych.{" "}
        </p>
        <p>
          Dane osobowe mogą być przekazywane także innym podmiotom powiązanym z
          Trust, posiadających uprawnienia współadministratora.{" "}
        </p>
        <p>
          Wszystkie podmioty, które przetwarzają na zlecenie Administratora dane
          osobowe zobowiązane są zapewnić odpowiednie środki techniczne i
          organizacyjne w celu zapewnienia ich ochrony. Podmioty przetwarzające
          dane osobowe na zlecenie Trust mogą je przetwarzać jedynie na jego
          udokumentowane polecenie.{" "}
        </p>
        <p>
          Dane osobowe przetwarzane są na terenie Europejskiego Obszaru
          Gospodarczego.
        </p>

        <h3>
          PRZEKAZYWANIE DANYCH OSOBOWYCH POZA EUROPEJSKI OBSZAR GOSPODARCZY
        </h3>
        <p>
          Dane osobowe mogą zostać przekazane do kraju trzeciego (np. w ramach
          świadczonych na rzecz Administratora usług informatycznych) wtedy, gdy
          spełnione są warunki określone w przepisach prawa, w tym w
          szczególności, gdy:
        </p>
        <ol>
          <li>
            kraj, do którego przekazywane są dane osobowe zapewnia odpowiedni
            poziom ochrony zgodnie z decyzją Komisji Europejskiej;
          </li>
          <li>
            dane są przekazywane na podstawie standardowych klauzul umownych
            przyjętych przez Komisję Europejską;
          </li>
          <li>
            przekazanie jest niezbędne do wykonania umowy między Administratorem
            a Użytkownikiem;
          </li>
          <li>
            przekazanie jest niezbędne do ustalenia, dochodzenia lub ochrony
            roszczeń.{" "}
          </li>
        </ol>

        <h3>PROFILOWANIE I PODEJMOWANIE ZAUTMATYZOWANYCH DECYZI</h3>
        <p>
          Dane osobowe w Serwisie będą przetwarzane w sposób zautomatyzowany,
          ale w wyniku takiego przetwarzania nie będą zapadały żadne decyzje
          wywołujące skutki prawne, finansowe lub inne podobne dla Użytkownika.
          Takie przetwarzanie może polegać przykładowo na tworzeniu odpowiednich
          kategorii klientów i przedstawianiu im ofert z uwzględnieniem ich
          indywidualnych potrzeb.
        </p>

        <h3>PRAWA UŻYTKOWNIKA</h3>
        <p>Każdy Użytkownik ma prawo do:</p>
        <ol>
          <li>
            dostępu do swoich danych osobowych - w każdym czasie Użytkownik może
            dowiedzieć się jakie dane są przetwarzane oraz otrzymać kopię tych
            danych;{" "}
          </li>
          <li>
            sprostowania swoich danych osobowych – jeżeli zauważy, że jego dane
            są nieprawidłowe lub nastąpiła ich zmiana;
          </li>
          <li>
            usunięcia swoich danych osobowych - gdy dane nie będą już niezbędne
            do celów, dla których zostały zebrane przez Administratora, gdy
            Użytkownik zgłosił sprzeciw wobec przetwarzania danych, gdy dane
            będą przetwarzane niezgodnie z prawem, gdy usunięcie danych jest
            niezbędne do wywiązania się z obowiązku wynikającego z przepisu
            prawa;
          </li>
          <li>
            ograniczenia przetwarzania swoich danych osobowych, gdy dane są
            nieprawidłowe, gdy są przetwarzane niezgodnie z prawem ale
            Użytkownik nie chce, by zostały usunięte, gdy wniesiony zostanie
            sprzeciw wobec przetwarzania - do czasu, w którym Administrator
            ustali czy podstawy przetwarzania przeważają nad podstawą sprzeciwu
            Użytkownika;
          </li>
          <li>
            przenoszenia swoich danych osobowych, gdy dane są przetwarzane w
            wykonaniu umowy, Użytkownikowi przysługuje prawo do przekazania ich
            innemu wskazanemu podmiotowi, chyba że wpływa to niekorzystnie na
            prawa i wolności innych;
          </li>
          <li>
            wycofania zgody na przetwarzanie danych osobowych w dowolnym
            momencie, gdy dane są przetwarzane na podstawie udzielonej przez
            Użytkownika zgody; wycofanie zgody wywołuje jednak skutki jedynie na
            przyszłość, tj. nie wpływa na zgodność z prawem przetwarzania, które
            miało miejsce na podstawie zgody przed jej wycofaniem;
          </li>
          <li>
            wniesienia sprzeciwu wobec przetwarzania danych osobowych, z
            przyczyn związanych ze szczególną sytuacją - gdy przetwarzanie
            odbywa się do celów wynikających z prawnie uzasadnionych interesów
            realizowanych przez Administratora, tj. na podstawie 6 ust. 1 pkt f)
            RODO;
          </li>
          <li>
            wniesienia skargi do organu nadzorczego tj. do Prezesa Urzędu
            Ochrony Danych Osobowych (Urząd Ochrony Danych Osobowych, ul. Stawki
            2, 00-193 Warszawa).
          </li>
        </ol>
        <p>
          Wycofanie zgody wywołuje skutki jedynie na przyszłość, tj. nie wpływa
          na zgodność z prawem przetwarzania, które miało miejsce na podstawie
          zgody przed jej wycofaniem.
        </p>

        <h3>PLIKI COOKIES</h3>
        <p>
          Serwis używa plików cookies. Są to niewielkie pliki tekstowe wysyłane
          przez serwer www i przechowywane przez oprogramowanie komputera
          przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną,
          witryna rozpoznaje rodzaj urządzenia, z którego łączy się Użytkownik.
          Parametry pozwalają na odczytanie informacji w nich zawartych jedynie
          serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z
          wcześniej odwiedzonych witryn. Gromadzone informacje dotyczą adresu
          IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu
          operacyjnego, dostawcy usług internetowych, informacji o czasie i
          dacie, lokalizacji oraz informacji przesyłanych do witryny za
          pośrednictwem formularza kontaktowego.
        </p>
        <p>
          Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
          Użytkownicy korzystają z witryn Administratora, aby usprawniać
          funkcjonowanie Serwisu, zapewniając bardziej efektywną i bezproblemową
          nawigację. Cookies identyfikuje Użytkownika, co pozwala na dopasowanie
          treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego
          preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego
          reklam.
        </p>
        <p>
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:
        </p>
        <ol>
          <li>
            sesyjne – są to pliki tymczasowe, które przechowywane są w
            urządzeniu końcowym do czasu opuszczenia Serwisu lub wyłączenia
            oprogramowania (przeglądarki internetowej); oraz
          </li>
          <li>
            stałe – są to pliki przechowywane są w urządzeniu końcowym przez
            czas określony w parametrach tych plików cookies lub do czasu ich
            usunięcia przez Użytkownika.
          </li>
        </ol>
        <p>
          Użytkownik może w każdym czasie zmienić ustawienia dotyczące plików
          cookies, określając warunki ich przechowywania i uzyskiwania dostępu
          przez pliki cookies do urządzenia końcowego. Zmian można dokonać za
          pomocą ustawień przeglądarki internetowej.
        </p>
        <p>
          Szczegółowe informacje o możliwości i sposobach obsługi plików cookies
          dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
        </p>
        <p>
          Możliwe jest usunięcie w każdej chwili plików cookies, korzystając z
          dostępnych funkcji w przeglądarce internetowej.
        </p>
        <p>
          Ograniczenie stosowania plików cookies może wpłynąć na niektóre
          funkcjonalności dostępne w Serwisie.
        </p>

        <h3>BEZPIECZEŃSTWO DANYCH OSOBOWYCH</h3>
        <p>
          Administrator na bieżąco prowadzi analizę ryzyka w celu zapewnienia,
          że dane osobowe przetwarzane są przez niego w sposób bezpieczny –
          zapewniający przede wszystkim, że dostęp do danych mają jedynie osoby
          upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu
          na wykonywane przez nie zadania. Administrator dba o to, by wszystkie
          operacje na danych osobowych były rejestrowane i dokonywane jedynie
          przez uprawnionych pracowników i współpracowników.
        </p>
        <p>
          Administrator podejmuje wszelkie niezbędne działania, by także jego
          podwykonawcy i inne podmioty współpracujące dawały gwarancję
          stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy
          przetwarzają Dane osobowe na zlecenie Administratora.
        </p>
      </div>
    </section>
  )
}

export default Content
